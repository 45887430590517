/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        var client = '';
        // var client = '/sjmcontrols';

        // STICKY HEADER
          var scrollPosition = $(window).scrollTop(); //-- Scroll position
          function scrollCheck() {
            // console.log(scrollPosition);
            scrollPosition = $(window).scrollTop();
            if(scrollPosition > 0) {
              $('header').addClass('sticky');
            } else if(scrollPosition === 0) {
              $('header').removeClass('sticky');
            }
            if(scrollPosition > 100) {
              $('header, .brand').addClass('scrolled');
            } else if(scrollPosition <= 100 ) {
              $('header, .brand').removeClass('scrolled');
            }
          }
          $(window).on('load scroll', function() {
            scrollCheck();
          });
          $(document).ready(function(){
            scrollCheck();
          });


        // Back to top scroll button
            //-- Also use this for the down click on the hero panel.
            //-- Rotate and change function after click or scroll
            // if($('div.page-header').find('img').length || $('body').hasClass('home')) {
            //   $('.backToTop.down').show();
            // }
            // $('.backToTop.up').hide();
            $(window).on('load scroll', function(){
              var scrollPos = window.pageYOffset;
              if(scrollPos < 100){
                if($('div.page-header').find('img').length || $('body').hasClass('home')) {
                  $('.backToTop.down').show();
                }
                $('.backToTop.up').hide();
              } else if(scrollPos >= 100){
                if($('div.page-header').find('img').length || $('body').hasClass('home')) {
                  $('.backToTop.down').hide();
                }
                $('.backToTop.up').show();
              }
            });

            $('.backToTop.down').on('click', function() {
              if($('body').hasClass('home')) {
                $('html, body').animate({
                    scrollTop: $(".page-header").next().offset().top - 100
                }, 800);
              } else if($('div.page-header').find('img').length) {
                $('html, body').animate({
                  scrollTop: $(".page-header").next().offset().top - 100
                }, 800);
              }
            });
            // }
            $('.backToTop.up').on('click', function(){
              $('html, body').animate({
                scrollTop: 0,
              }, 800);
            });


        // Mobile navigation
            $('.nav-icon').on('click', function(){
              // console.log(scrollPosition);
              $('.nav-icon').toggleClass('open');
              // $('#nav-icon').toggleClass('open');
              if($('.nav-icon').hasClass('open')) {
                // Add Classes
                $('body, .wrap, header, footer, .mobile-menu, .brand').addClass('menu-opened');
              } else {
                // Remove Classes
                $('body, .wrap, header, footer, .mobile-menu, .brand').removeClass('menu-opened');
              }
            });

            $('.mobile-menu .nav > li.menu-item-has-children').each(function(){

              var parent = $(this);

              $(parent).prepend('<span class="menu-click"><i class="fas fa-plus"></i></span>');

              $(parent).find('span.menu-click').on('click', function() {

                parent.toggleClass('clicked');
                if(parent.hasClass('clicked')) {

                  $('.mobile-menu .nav > li').removeClass('clicked');
                  $('ul.sub-menu').removeClass('clicked');

                  parent.addClass('clicked');


                  $('span.menu-click').find('i').removeClass('fa-minus');
                  $('span.menu-click').find('i').addClass('fa-plus');

                  $(this).find('i').removeClass('fa-plus');
                  $(this).find('i').addClass('fa-minus');

                  parent.find('ul.sub-menu').addClass('clicked');

                } else {

                  $('.mobile-menu .nav > li').removeClass('clicked');

                  $('ul.sub-menu').removeClass('clicked');

                  $(this).find('i').addClass('fa-plus');
                  $(this).find('i').removeClass('fa-minus');

                  parent.find('ul.sub-menu').removeClass('clicked');

                }
              });
            });


        // Main Navigation
        //-- Make sure current menu items 
        //-- display for relevant post-types

        //---- Services Post Type
        $('body.single.single-sjm_services .menu-item').each(function(){
          $(this).removeClass('current_page_parent');
          var navText = $(this).find('a').text();
          if(navText === 'Services') {
            $(this).addClass('current_page_parent');
            $(this).find('a').css({ color : '#2489c2' });
          }
        });

        //---- Case Studies Post Type
        $('body.single.single-case_studies .menu-item').each(function(){
          $(this).removeClass('current_page_parent');
          var navText = $(this).find('a').text();
          if(navText === 'Case Studies') {
            $(this).addClass('current_page_parent');
            $(this).find('a').css({ color : '#2489c2' });
          }
        });



        // AJAX Pagination
            var $ajax_list = $('div.posts');
            if ($ajax_list.length) {
              $('body').on('click', '#ajax-pagination', function (e) {
                e.preventDefault();

                var activeClass = 'disabled';

                if ($(this).hasClass(activeClass)) {
                  return;
                }

                $(this).addClass(activeClass);

                $(this).find('span').text('Loading');

                $.ajax({
                  url: $(this).attr('href'),
                  dataType: 'html',
                  complete: function () {
                    $(this).removeClass(activeClass);
                  },
                  success: function (data) {
                    // update list
                    $ajax_list
                      .append($(data).find('div.posts').html());

                      console.log($ajax_list);

                    // lazyBackground.update();
                    // $ajax_list.refreshSamples();
                    $('.link-card-layout:not(:eq(0))').find('.large-card').removeClass('large-card');

                    // update button
                    $('#ajax-pagination').replaceWith($(data).find('#ajax-pagination'));
                  },
                  error: function () {},
                });
              });
            }


  
        // SLIDERS
          //-- LOGO BANNER
          $('.logo-banner-content').slick({
            accessibility: true,
            slidesToShow: 2,
            slidesToScroll: 2,
            prevArrow: '<div class="slick-button slick-prev"></div>',
            nextArrow: '<div class="slick-button slick-next"></div>',
            dots: false,
            autoplay: true,
            autoplaySpeed: 6000,
            mobileFirst: true,
            responsive: [
              {
                breakpoint: 1200,
                settings: {
                  slidesToShow: 5,
                  slidesToScroll: 5,
                }
              },
              {
                breakpoint: 767,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 3,
                }
              }
            ]
          });


        // GRAPHICS
          //-- Slider logos equal heights
          $('.logo-banner-content .logo').each(function(){
            var width = $(this).outerWidth();
            $(this).css({ height : width });
          });

          //-- Append arrow to buttons
          $('.button, .wp-block-button__link').each(function(){
            $(this).append('<img src="'+client+'/wp-content/themes/sjmcontrols/dist/images/button-arrow.svg" class="svg" />');
          });


          // Add equal class to gallery images to make them square
          $('.wp-block-gallery figure.wp-block-image').each(function(){
            $(this).addClass('equal');
          });


          // Square image function
          //-- Get width then set the height to the same value
          function square_images() {
            var width = $('.equal').width();
            $('.equal').css({ height : width });
          }
          square_images();

          var _refresh = function () {
            square_images();
          };
          $(window).on('resize', _refresh);
          $(document).on('ready', _refresh);
          $(window).on('load', _refresh);



          // -- Change images with svg class to svg
          jQuery('img.svg').each(function(){
            var $img = jQuery(this);
            var imgID = $img.attr('id');
            var imgClass = $img.attr('class');
            var imgURL = $img.attr('src');
                jQuery.get(imgURL, function(data) {
                    // Get the SVG tag, ignore the rest
                    var $svg = jQuery(data).find('svg');
                    // Add replaced image's ID to the new SVG
                    if(typeof imgID !== 'undefined') {
                        $svg = $svg.attr('id', imgID);
                    }
                    // Add replaced image's classes to the new SVG
                    if(typeof imgClass !== 'undefined') {
                        $svg = $svg.attr('class', imgClass+' replaced-svg');
                    }
                    // Remove any invalid XML tags as per http://validator.w3.org
                    $svg = $svg.removeAttr('xmlns:a');
                    // Replace image with new SVG
                    $img.replaceWith($svg);
                }, 'xml');
          });


      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
